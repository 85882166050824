import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles, Typography, Button, Box, Chip,
} from '@material-ui/core';
import Link from '../shared/LocalizedLink';
import { FaChevronRight } from 'react-icons/fa';
import classnames from 'classnames';
import OfferWindow from '../shared/OfferWindow';
import SectionContainer from '../shared/SectionContainer';
import TranslatedText from '../shared/TranslatedText';

const useStyles = makeStyles((theme) => ({
  link:{
    textDecoration: 'none'
  },
  grid: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 400px)',
    justifyContent: 'center',
    maxWidth: 1200,
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    width: '100%',
    maxWidth: `calc(100vw - ${theme.spacing(5)}px)`,
    marginLeft: 'auto',
    marginRight: 'auto',
    boxSizing: 'border-box',
  },
  offerBox: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: 270,
    borderRadius: theme.spacing(1),
    boxShadow: '0px 8px 10px rgba(0,0,0,0.1)',
    overflow: 'hidden',
    backgroundColor: theme.palette.common.white,
  },
  offerBoxNoPosition: {
    backgroundColor: theme.palette.background.lightBlue,
    '& p': {
      color: theme.palette.primary.main,
      fontSize: 21,
      letterSpacing: 0.2,
      fontWeight: 100,
      lineHeight: '25px',
    },
  },
  offerBoxNoPositionButton: {
    padding: theme.spacing(1),
    width: theme.spacing(6),
    height: theme.spacing(6),
    minWidth: theme.spacing(6),
    boxShadow: 'none',
    '& > span > a': {
      display: 'flex',
      color: theme.palette.common.white,
    },
  },
  offerHeaderIndicator: {
    height: theme.spacing(0.7),
    minHeight: theme.spacing(0.7),
    backgroundColor: '#308FC1',
  },
  offerDetails: {
    flex: 1,
    boxSizing: 'border-box',
    padding: theme.spacing(2),
    overflow: 'hidden',
  },
  offerTitle: {
    fontSize: 21,
    letterSpacing: 0.2,
    fontWeight: 100,
    lineHeight: '25px',
  },
  offerLocationAndType: {
    padding: `${theme.spacing(0.5)}px 0`,
    color: theme.palette.text.hint,
    letter: 0.11,
  },
  offerChips: {
    maxHeight: 76,
  },
  offerChip: {
    borderRadius: '3px',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  offerChipLabel: {
    color: theme.palette.text.hint,
    fontSize: 10,
  },
  offerFooter: {
    backgroundColor: '#F4F7FC',
  },
  offerButton: {
    height: 36,
  },
  offerButtonLabel: {
    fontSize: 15,
    lineHeight: '19px',
  },
}));

const CareerOffers = React.forwardRef(({ offers, detailedOffers, location }, ref) => {
  const [openedOfferId, setOpenedOfferId] = useState(null);
  const classes = useStyles();
  useEffect(()=>{
    if(location && location.search){
        const params = new URLSearchParams(location.search);
        setOpenedOfferId(params.get('offer'))
    }
  },[location]);
  return (
    <SectionContainer
      header={<TranslatedText id="career.offer.header" />}
      subheader={<TranslatedText id="career.offer.subheader" />}
      withBackgroundBox
      upMdPaddingX={5}
      ref={ref}
    >
      <Box className={classes.grid}>
        {[...offers].reverse().map((offer) => {
          const { keywords } = detailedOffers.find((item) => item.shortcode === offer.shortcode);
          return (
            <Box key={offer.id} className={classes.gridItem}>
              {offer.code === null && offer.department === null ? (
                <Box className={classnames(classes.offerBox, classes.offerBoxNoPosition)}>
                  <Box py={3} px={2} flex={1}>
                    <Typography variant="body1"><TranslatedText id="career.offer.noOpenPosition" /></Typography>
                  </Box>
                  <Box p={2} display="flex" justifyContent="flex-end">
                    <Button variant="contained" color="primary" className={classes.offerBoxNoPositionButton}>
                      <a target="_blank" rel="noreferrer" href="https://apply.workable.com/novomatic/j/616E90624C/">
                        <FaChevronRight />
                      </a>
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box className={classes.offerBox}>
                  <Box className={classes.offerHeaderIndicator} />
                  <Box className={classes.offerDetails}>
                    <Box>
                      <Typography color="primary" className={classes.offerTitle}>{offer.title}</Typography>
                      <Typography className={classes.offerLocationAndType} variant="caption" component="div">
                        {`${offer.employment_type ? offer.employment_type : 'Full time'} `}
                        {offer.location && `| ${offer.location.city}`}
                      </Typography>
                    </Box>
                    {keywords && !!keywords.length && (
                      <Box flex="1 1 0%" className={classes.offerChips}>
                        {keywords.map((keyword) => ((keyword.length < 20) ? (
                          <Chip
                            key={keyword}
                            classes={{ root: classes.offerChip, label: classes.offerChipLabel }}
                            variant="outlined"
                            label={keyword}
                          />
                        ) : null)).splice(0, 7)}
                      </Box>
                    )}
                  </Box>
                  <Box p={2} className={classes.offerFooter}>
                    <Link className={classes.link} to={`/careers?offer=${offer.id}#offersSection`}>
                      <Button
                        fullWidth
                        onClick={()=>{setOpenedOfferId(offer.id);}}
                        variant="contained"
                        color="primary"
                        classes={{ root: classes.offerButton, label: classes.offerButtonLabel }}
                      >
                        <TranslatedText id="career.offer.applyButton" />
                      </Button>
                    </Link>
                  </Box>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
      {openedOfferId && (
        <OfferWindow
          location={location}
          offer={offers.find((offer) => offer.id === openedOfferId)}
          onClose={() => { setOpenedOfferId(null); }}
          isJobOffer
        />
      )}
    </SectionContainer>
  );
});

CareerOffers.propTypes = {
  offers: PropTypes.arrayOf(PropTypes.shape({})),
  detailedOffers: PropTypes.arrayOf(PropTypes.shape({})),
};
CareerOffers.defaultProps = {
  offers: [],
  detailedOffers: [],
};

export default CareerOffers;
